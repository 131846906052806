import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./pflege-von-grnanlagen.css";
import "./new_css/pflege-von-gruenanlagen.css";
import "./new_css/nav.css";

const PflegeVonGrnanlagen = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const handleNavOpen = () => {
    setOpenNav(!openNav);
  };
  return (
    <div className="pflege-von-grnanlagen-container">
      <Helmet>
    <title>Professionelle Grünanlagenpflege – Garten- & Landschaftspflege | Dienstleistungen Südwest</title>
    <meta name="description" content="Professionelle Pflege von Grünanlagen – von Rasenmähen über Heckenschnitt bis Unkrautentfernung. Jetzt zuverlässigen Service buchen! ISO 9001 zertifiziert." />
    <meta name="keywords" content="Grünanlagenpflege, Gartenpflege, Rasenmähen, Hecken schneiden, Unkrautentfernung, Landschaftspflege, Außenanlagen" />
    
    <meta property="og:title" content="Professionelle Pflege von Grünanlagen | Dienstleistungen Südwest" />
    <meta property="og:description" content="Grünanlagen professionell pflegen lassen – inklusive Rasenpflege, Heckenschnitt und Unkrautbeseitigung. Jetzt unverbindlich anfragen!" />
    <meta property="og:image" content="/gruenanlagenpflege.jpg" />
    <meta property="og:type" content="website" />
    
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://dienstleistungen-suedwest.de/gruenanlagenpflege" />

    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Dienstleistungen Südwest",
        "image": "https://dienstleistungen-suedwest.de/1585861357%20(2)-1500h.png",
        "url": "https://dienstleistungen-suedwest.de/",
        "telephone": "072221588361",
        "email": "kontakt@dienstleistungen-suedwest.de",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Im Wöhr 3",
          "postalCode": "76437",
          "addressLocality": "Rastatt",
          "addressCountry": "DE"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 48.86273,
          "longitude": 8.2326942
        },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          "opens": "08:00",
          "closes": "17:00"
        },
        "areaServed": ["Rastatt", "Baden-Baden", "Bühl", "Gaggenau", "Karlsruhe"],
        "serviceType": ["Grünanlagenpflege", "Gartenpflege", "Landschaftspflege"],
        "description": "Professionelle Pflege von Grünanlagen für Privat- und Gewerbekunden. Rasenpflege, Heckenschnitt und Unkrautbeseitigung – zuverlässig und ISO 9001 zertifiziert.",
        "hasCredential": {
          "@type": "EducationalOccupationalCredential",
          "credentialCategory": "certification",
          "name": "ISO 9001",
          "recognizedBy": "ISO"
        }
      }
      `}
    </script>
</Helmet>
      <div className="pflege-von-grnanlagen-container1"></div>
      <div className="pflege-von-grnanlagen-container2">
        <div className="pflege-von-grnanlagen-container3">
          <div className="pflege-von-grnanlagen-container4">
            <span className="pflege-von-grnanlagen-text">
              Pflege von Grünanlagen
            </span>
            <span>
              <span>Die Pflege von Grünanlagen ist unsere Spezialität.</span>
              <br></br>
              <span>Unser Service umfasst unteranderem:</span>
              <br></br>
            </span>
            <ul className="pflege-von-grnanlagen-ul list">
              <li className="list-item">
                <span>Anlegen von Blumenbeeten</span>
              </li>
              <li className="list-item">
                <span>Heckenschneiden</span>
              </li>
              <li className="list-item">
                <span>Zurückschneiden von Sträuchern und Bäumen</span>
              </li>
              <li className="list-item">
                <span>Sichtschutz</span>
              </li>
              <li className="list-item">
                <span>
                  <span>Rollrasen legen</span>
                  <br></br>
                </span>
              </li>
              <li className="list-item">
                <span>Vertikulieten</span>
              </li>
              <li className="list-item">
                <span>Düngen</span>
              </li>
              <li className="list-item">
                <span>Beetpflege</span>
              </li>
            </ul>
          </div>
          <div className="pflege-von-grnanlagen-container5">
            <img
              alt="Grünanlange"
              src="/image%20(2)-300h.jpg"
              className="pflege-von-grnanlagen-image"
            />
          </div>
        </div>
        <div className="pflege-von-grnanlagen-container6">
          <span className="pflege-von-grnanlagen-text16">
            <span>Gartengestaltung</span>
            <br></br>
          </span>
          <div
            data-thq="slider"
            data-loop="true"
            data-autoplay="true"
            data-navigation="true"
            data-pagination="true"
            className="pflege-von-grnanlagen-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pflege-von-grnanlagen-slider-slide swiper-slide"
              >
                <span className="grünanlagen-text011">
                  <span>Gartenarbeiten</span>
                  <br></br>
                </span>
              </div>
              <div
                data-thq="slider-slide"
                className="pflege-von-grnanlagen-slider-slide1 swiper-slide"
              >
                <span className="grünanlagen-text012">
                  <span>Baumrückschnitt</span>
                  <br></br>
                </span>
              </div>
              <div
                data-thq="slider-slide"
                className="pflege-von-grnanlagen-slider-slide2 swiper-slide"
              >
                <span className="grünanlagen-text013">
                  <span>Vorgarten-Gestaltung</span>
                  <br></br>
                </span>
              </div>
              <div
                data-thq="slider-slide"
                className="pflege-von-grnanlagen-slider-slide3 swiper-slide"
              >
                <span className="grünanlagen-text014">
                  <span>Vorgarten-Gestaltung</span>
                  <br></br>
                </span>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pflege-von-grnanlagen-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="swiper-button-next"
            ></div>
          </div>
          <span className="pflege-von-grnanlagen-text19">
            <br></br>
            <span>
              Hinter jeder grünen Oase steckt meist viel Arbeit, die mit viel
              Liebe zum Detail erledigt werden muss. Schon lange ist die Pflege
              von Grünanlagen eine unserer Spezialitäten. Ob es sich dabei um
              private Gartenpflege handelt, oder größere Grünanlagen im
              gewerblichen und öffentlichen Bereich.
            </span>
            <br></br>
            <br></br>
            <span>
              Wir nehmen uns jede Grünanlage mit einer zuverlässigen und
              regemäßigen Pflege an, um daraus einen Ort des Wohlfühlens zu
              gestalten.
            </span>
            <br></br>
            <span>
              Mit unserem umfassenden Equipment, Maschinen und Fahrzeugen setzen
              wir Ihre Grünanlage voll in Szene.
            </span>
            <br></br>
            <br></br>
            <span>
              Unser Team aus qualifizierten und hoch motivierten Mitarbeitern
              garantiert eine exzellente Umsetzung nach Ihren Vorstellungen.
            </span>
            <br></br>
            <span>
              Nach einer ausführlichen Beratung bezüglich Wünsche und
              Bedürfnisse für Ihren Garten und Ihre Grünanlage, realisiert unser
              Team fachlich und termingerecht.
            </span>
            <br></br>
            <br></br>
            <span>
              Wir bieten Ihnen unsere Dienstleistung das ganze Jahr über an und
              berücksichtigen dabei aber auch saisonale Arbeiten.
            </span>
            <br></br>
            <br></br>
            <span>
              Kontaktieren Sie uns und lassen Sie sich beraten, damit wir auch
              Ihre Grünanlage pflegen.
            </span>
            <br></br>
            <br></br>
            <span>Unser Service umfasst unteranderem:</span>
          </span>
          <ul className="pflege-von-grnanlagen-ul1 list">
            <li className="list-item">
              <span>Neuanpflanzungen</span>
            </li>
            <li className="list-item">
              <span>Pflege und Neuanlagen von Blumenbeeten</span>
            </li>
            <li className="list-item">
              <span>Rückschnitt von Hecken, Sträuchern und Obstbäumen</span>
            </li>
            <li className="list-item">
              <span>
                Rasenarbeiten: Mähen, Neuauflage, Vertikutieren von
                Rasenflächen, Rollrasen legen, Düngen
              </span>
            </li>
            <li className="list-item">
              <span>Holz-, Drahtzäune</span>
            </li>
            <li className="list-item">
              <span>
                Sichtschutz, für Beete und Terrassen oder einfach nur zum Design
              </span>
            </li>
            <li className="list-item">
              <span>Bewässerung und Bewässerungssysteme</span>
            </li>
            <li className="list-item">
              <span>Entsorgung von Laub, Rasen und Unrat</span>
            </li>
            <li className="list-item">
              <span>Entsorgung von Wildwuchs und Unkraut</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="logoHeaderContainer">
            <div className="home-container01">
              <Link to="/" className="home-navlink">
                <img
                  alt="Dienstleistungen Südwest Logo"
                  src="/1585861357%20(2)-1500h.png"
                  className="home-image"
                />
              </Link>
            </div>
            <span className="home-text">Dienstleistungen Südwest</span>
          </div>
          <div className="home-container02">
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <a href="#Home" className="home-link">
                  Home
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                  className="home-link1"
                >
                  Dienstleistungen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Referenzen"
                  className="home-link2"
                >
                  Referenzen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Über%20uns"
                  className="home-link3"
                >
                  Über uns
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Kontakt"
                  className="home-link4"
                >
                  Kontakt
                </a>
              </nav>
              <div className="home-buttons">
                <a
                  href="https://jobs.dienstleistungen"
                  className="home-link5 button"
                >
                  <span className="home-text001">
                    <span>Stellenauschreibung</span>
                    <br></br>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={() => handleNavOpen()}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>

          {openNav ? (
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div className="home-nav">
                <div className="home-top">
                  <img
                    alt="Dienstleistungen Südwest Logo"
                    src="/1585861357%20(2)-1500h.png"
                    className="home-logo"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="home-close-menu"
                    onClick={() => handleNavOpen()}
                  >
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <a
                    href="/"
                    onClick={() => handleNavOpen()}
                    className="home-link"
                  >
                    Home
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                    onClick={() => handleNavOpen()}
                    className="home-link1"
                  >
                    Dienstleistungen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Referenzen"
                    onClick={() => handleNavOpen()}
                    className="home-link2"
                  >
                    Referenzen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Über%20uns"
                    onClick={() => handleNavOpen()}
                    className="home-link3"
                  >
                    Über uns
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Kontakt"
                    onClick={() => handleNavOpen()}
                    className="home-link4"
                  >
                    Kontakt
                  </a>
                </nav>
                <div className="home-buttons">
                  <a
                    href="https://jobs.dienstleistungen"
                    className="home-link5 button"
                  >
                    <span className="home-text001">
                      <span>Stellenauschreibung</span>
                      <br></br>
                    </span>
                  </a>
                </div>
              </div>
              <div className="nav-socials">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          ) : null}
        </header>
      </div>
      <footer className="home-footer">
        <img
          alt="Dienstleistungen Südwest Logo"
          src="/1585861357%20(2)-1500h.png"
          className="home-image12"
        />
        <Link to="/impressum" className="home-navlink6">
          <span>Impressum</span>
          <br></br>
        </Link>
        <Link to="/datenschutz" className="home-navlink6">
          <span>Datenschutz</span>
          <br></br>
        </Link>
        <div className="home-icon-group1">
          <a
            href="https://instagram.com/dienstleistungen_suedwest/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link7"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default PflegeVonGrnanlagen;
