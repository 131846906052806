import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import './style.css'
import PflegeVonGrnanlagen from './views/pflege-von-grnanlagen'
import Gebudereinigung from './views/gebudereinigung'
import Home from './views/home'
import Grabpflege from './views/grabpflege'
import Winterdienst from './views/winterdienst'
import Hausmeisterdienste from './views/hausmeisterdienste'
import Impressum from './views/impressum'
import Datenschutz from './views/datenschutz'
import NotFound from './views/not-found'
import emailjs from '@emailjs/browser';
import CookieConsent from "react-cookie-consent";
import { Helmet } from 'react-helmet';
const App = () => {
  return (
    <Router>
      <CookieConsent
  location="bottom"
  buttonText="Annehmen"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
  
>
Wir nutzen Cookies um Ihren Aufenthalt auf unserer Seite zu verbessern. Sie akzeptieren unsere Cookies wenn Sie weiterhin auf unserer Webseite bleiben.{" "}
<a href="/datenschutz" className="index-link1">
                  Datenschutzerklärung
                </a>
                <link rel="icon" href="bild_32x32.png" type="image/x-icon"/>
</CookieConsent>
<Helmet>
        <html lang="de" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/bild_32x32.png" type="image/x-icon"/>
        <link rel="canonical" href="https://dienstleistungen-suedwest.de/" />
        {/* Default meta tags that can be overridden by individual pages */}
        <title>Dienstleistung Südwest - Ihr verlässlicher Partner</title>
        <meta 
          name="description" 
          content="Professionelle Dienstleistungen in Gebäudereinigung, Grünanlagenpflege, Grabpflege, Hausmeisterdienste und Winterdienst in Südwestdeutschland. ISO 9001 Zertifiziert" 
        />
        <meta name="robots" content="index, follow" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="Dienstleistung Südwest" />
        <meta property="og:description" content="Professionelle Dienstleistungen in Gebäudereinigung, Grünanlagenpflege, Grabpflege, Hausmeisterdienste und Winterdienst. ISO 9001 Zertifiziert" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dienstleistungen-suedwest.de/" />
        <meta property="og:image" content="https://dienstleistungen-suedwest.de/1585861357 (2)-200h.png" />
      </Helmet>
      <Switch>
        <Route
          component={PflegeVonGrnanlagen}
          exact
          path="/pflege-von-gruenanlagen"
        />
        <Route component={Gebudereinigung} exact path="/gebaeudereinigung" />
        <Route component={Home} exact path="/" />
        <Route component={Grabpflege} exact path="/grabpflege" />
        <Route component={Winterdienst} exact path="/winterdienst" />
        <Route component={Hausmeisterdienste} exact path="/hausmeisterdienste" />
        <Route component={Impressum} exact path="/impressum" />
        <Route component={Datenschutz} exact path="/datenschutz" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
